@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-montserrat;
  }
}

.line-through-custom {
  position: relative;
}

.line-through-custom::before {
  content: '';
  position: absolute;
  left: 42%;
  top: 50%;
  width: 15%;
  height: 1px; /* Adjust the thickness of the line */
  background-color: red; /* Change this to your desired color */
  transform: translateY(-50%);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
